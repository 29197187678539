<template>
  <table class="table">
    <tbody>
      <template>
        <template v-if="assets.length > 1">
          <tr>
            <td colspan="2">
              <span class="text-strong h6">{{ $t('INCLUDED') }}</span>
            </td>
          </tr>
          <tr v-for="asset in assets" :key="`${asset.displayIndex}-asset`">
            <td colspan="2" class="table__icon table__icon--no-padding">
              <StihlServiceIcon
                :icon-type="asset.additionalData.productType"
                class="icon table__icon-icon"
              />
              <b-container class="table__icon-text">
                <b-row class="align-items-center">
                  <b-col cols="8">
                    {{ formatAssetName(asset) }}
                  </b-col>
                  <b-col cols="4">
                    <img
                      v-if="showBatteryIcon(asset.skuCode)"
                      :data-src="
                        require(`@/assets/images/${getBatteryIcon(
                          asset.skuCode,
                        )}`)
                      "
                      alt="BatteryLogo"
                      class="lazyload table__batter-icon"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </td>
          </tr>
        </template>

        <tr>
          <td colspan="2">
            <span class="text-strong h6">{{ primaryAsset.name }}</span>
          </td>
        </tr>

        <tr>
          <td colspan="2" class="no-padding">
            <table class="table">
              <tr
                :key="`${serviceType}-${key}`"
                v-for="(serviceType, key) in SERVICE_TYPES"
              >
                <td class="table__icon table__icon--no-padding">
                  <StihlServiceIcon
                    :icon-type="serviceType"
                    class="icon table__icon-icon"
                  />
                  <template v-if="serviceType === 'WINTER_STORAGE'">
                    <p
                      v-if="bundleModel === 'IMOW_RMI'"
                      class="table__icon-text"
                    >
                      {{ $t(`SERVICES.WINTER_STORAGE_RMI`) }}
                    </p>
                    <p v-else class="table__icon-text">
                      {{ $t(`SERVICES.WINTER_STORAGE_OTHER`) }}
                    </p>
                  </template>
                  <template v-else>
                    <p class="table__icon-text">
                      {{ $t(`SERVICES.${serviceType.toUpperCase()}`) }}
                    </p>
                  </template>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </template>
      <tr v-if="backOfficeLoggedIn">
        <td>{{ $t('BUNDLE_PRODUCT_CODE') }}</td>
        <td class="text-right text-grey">
          {{ bundle.productCode }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_2') }}</td>
        <td class="text-right text-grey">
          {{ formatPrice(bundle.totalAmount) }}
          <span>kr.</span>
        </td>
      </tr>
      <tr v-if="showPeriod">
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_5') }}</td>
        <td class="text-right text-grey">
          {{ bundle.upgradePeriod }} {{ $t('MONTHS') }}
        </td>
      </tr>
      <tr v-if="showPeriod">
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_6') }}</td>
        <td class="text-right text-grey">
          {{ bundle.totalPeriod }} {{ $t('MONTHS') }}
        </td>
      </tr>
      <tr v-if="showPaymentAmount">
        <td class="text-strong">{{ $t('YOUR_PLAN_TABLE.CATEGORY_TOTAL') }}</td>
        <td class="text-right text-normal">
          <span class="text-strong h6">
            {{ bundle.paymentAmount }}
            <span>kr.</span>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';
import numeral from 'numeral';
import { StihlServiceIcon } from '@/components';
import { constants } from '@/mixins';
import { akBatterySkuCodes, apBatterySkuCodes } from '@/constants';

export default {
  name: 'YourPlanTable',
  mixins: [constants],
  components: {
    StihlServiceIcon,
  },
  props: {
    showPeriod: Boolean,
    showPaymentAmount: Boolean,
  },
  methods: {
    formatPrice(price) {
      return numeral(price).format('0,0').replace(',', '.');
    },
    formatAssetName(asset = {}) {
      return asset.quantity > 1
        ? `${asset.quantity} x ${asset.name}`
        : asset.name;
    },
    getBatteryIcon(skuCode) {
      if (akBatterySkuCodes.includes(skuCode)) {
        return 'L-AK-IC.png';
      } else if (apBatterySkuCodes.includes(skuCode)) {
        return 'L-AP_PRO-IC.png';
      }
    },
    showBatteryIcon(skuCode) {
      return (
        akBatterySkuCodes.includes(skuCode) ||
        apBatterySkuCodes.includes(skuCode)
      );
    },
  },
  computed: {
    ...mapState(['selectedBundle', 'backOfficeLoggedIn']),
    assets() {
      return this.selectedBundle?.assets
        .slice()
        .sort((a, b) => a.displayIndex - b.displayIndex);
    },
    bundle() {
      return this.selectedBundle || {};
    },
    primaryAsset() {
      return this.assets.find(
        (asset) => asset.additionalData.productLevel === 'PRIMARY',
      );
    },
    bundleModel() {
      return this.bundle && this.bundle.additionalData.model;
    },
  },
};
</script>

<style lang="scss">
.table__icon {
  position: relative;
  padding-top: rem(4px);
  padding-bottom: rem(4px);
  padding-left: 1rem;

  @include min-width(sm) {
    padding-left: 2rem;
  }
}

.table__icon--no-padding {
  padding-left: 0;
}

.table__icon-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: $color-white;
  font-size: rem(30px);

  @include min-width(sm) {
    left: 2rem;
  }

  .table__icon--no-padding & {
    left: 0;
  }
}

.table__batter-icon {
  height: 1.5rem;
}

.table__icon-text {
  padding-left: rem(48px);
}
</style>
